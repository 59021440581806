<template>
  <v-container class="pa-0">
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      @close="closeModal"
      @action="saveLocation"
    />
    <v-row class="content-area">
      <v-col
        class="btn-container"
        cols="12"
      >
        <input
          v-model="name"
          type="text"
          class="input-location-name mb-2"
          placeholder="새 위치 이름"
        >
        <input
          v-model="degree"
          type="number"
          min="0"
          max="360"
          class="input-location-degree mb-2"
          placeholder="로봇 각도 설정"
        >
        <v-select
          v-model="selectedType"
          class="select-input mb-2"
          :items="typeList"
          item-color="grey"
          placeholder="위치 속성"
          height="8vh"
          outlined
          dark
        />
        <template v-if="selectedType!=='LOCATION' && false">
          <v-select
            v-model="dockingType"
            class="select-input mb-2"
            :items="dockingList"
            item-color="grey"
            placeholder="자동 도킹 설정"
            height="8vh"
            outlined
            dark
          />
        </template>
        <template v-if="selectedType!=='LOCATION'&&dockingType==='지정 도킹'">
          <v-select
            v-model="dockingStationId"
            class="select-input"
            :items="dockingStationList"
            item-color="grey"
            placeholder="도킹 스테이션 선택"
            height="8vh"
            outlined
            dark
          />
        </template>
        <v-row class="btn-adding-row-container ma-0 pa-0">
          <v-col class="btn-adding-col-container ma-0 py-0">
            <v-btn
              class="btn-adding mt-8 mr-4"
              color="#7c7c7c"
              dark
              @click="cancelModifying"
            >
              취소
            </v-btn>
            <template v-if="name.length&&String(degree).length&&selectedType.length">
              <v-btn
                class="btn-adding mt-8 ml-4"
                color="#00BFB4"
                dark
                @click="showSaveModal"
              >
                완료
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                class="btn-adding mt-8 ml-4"
                color="#00BFB4"
                dark
                disabled
              >
                완료
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/defaultModal.vue';
import EventBus from '@/main.js';
import * as math3d from 'math3d';

export default {
  name: 'ModifyLocation',
  components: {
    DefaultModal,
  },
  props: {
    locationId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      showModal: false,
      modalState: {
        message: null,
        action: null
      },
      degree: '0',
      name: '',
      selectedType: null,
      typeList: [
        'DOCKING',
        'LOCATION',
        'LOAD',
        'UNLOAD',
        //'WORKSTATION',
      ],
      locations: null,
      dockingType: '지정 도킹',
      dockingList: [
        // '자동 도킹',
        '지정 도킹'
      ],
      dockingStationId: null,
      dockingStationList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    }
  },
  created() {
    EventBus.$emit('moveLocation');
    EventBus.$on('updateLocations', this.initialize);
    this.initialize();
  },
  beforeDestroy() {
    EventBus.$emit('cancelAddLocation');
    EventBus.$off('updateLocations');
  },
  methods: {
    initialize() {
      return this.fetchLocation()
        .then(this.setLocationData)
        .catch(err => {
          throw Error(err)
        });
    },
    fetchLocation() {
      return this.$axios.get('/location')
        .then(res => {
          if (res.status === 200) {
            this.locations = res.data;
          }
        });
    },
    setLocationData() {
      const Quaternion = math3d.Quaternion;
      let idx;
      this.locations.forEach((location, i) => {
        if (location.location_id === this.locationId) {
          idx = i;
          this.name = location.location_name;
          this.selectedType = location.location_type;
          const q = new Quaternion(
            location.location_position_qx,
            location.location_position_qy,
            location.location_position_qz,
            location.location_position_qw,
          );
          this.degree = Math.round(q.angleAxis.angle);
          if (location.location_type !== "LOCATION") {
            if (location.autodocking === "Y") {
              this.dockingType = "자동 도킹"
              this.dockingStationId = 0;
            } else {
              this.dockingType = "지정 도킹"
              this.dockingStationId = location.docking_id;
            }
          }
        }
      });
      this.locations.splice(idx, 1);
    },
    saveLocation() {
      // if (this.selectedType === 'DOCKING' && this.dockingType === null) {
      //   this.$toast('자동 도킹을 설정하세요');
      //   return;
      // }
      // if (this.selectedType === 'DOCKING' && this.dockingType === '지정 도킹' && this.dockingStationId === null) {
      //   this.$toast('도킹 스테이션을 선택하세요');
      //   return;
      // }
      // let ableToSave = this.locations.every(location => {
      //   return location.location_name !== this.name;
      // });
      // if(ableToSave) {
      if (this.degree < 0) {
        this.degree = 0
      } else if (this.degree > 360) {
        this.degree = 360
      }
      const data = {
        name: this.name,
        degree: this.degree,
        type: this.selectedType,
        autodocking: "Y",
        docking_id: 0
      };

      if (this.selectedType !== 'LOCATION' && this.dockingType === '지정 도킹') {
        data.autodocking = "N";
        data.docking_id = this.dockingStationId;
      }

      EventBus.$emit('saveModifiedLocation', data);
      this.$router.replace('/new-location');
      // } else {
      //   this.$toast('중복된 위치명입니다');
      //   this.showModal = false;
      // }
    },
    cancelModifying() {
      EventBus.$emit('cancelModifyingLocation');
      this.$router.replace('/new-location');
    },
    showSaveModal() {
      if (this.selectedType !== 'LOCATION' && this.dockingType === null) {
        this.$toast('자동 도킹을 설정하세요');
        return;
      }

      if (this.selectedType !== 'LOCATION' && this.dockingType === '지정 도킹' && this.dockingStationId === null) {
        this.$toast('도킹 스테이션을 선택하세요');
        return;
      }

      let ableToSave = this.locations.every(location => {
        return location.location_name !== this.name;
      });

      if (ableToSave) {
        this.modalState.message = '위치를 수정할까요?';
        this.modalState.action = '위치 수정';
        this.showModal = true;

      } else {
        this.$toast('중복된 위치명입니다');
        this.showModal = false;
      }
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.showModal = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.content-area {
  height: 100%;
}

.btn-container {
  margin: 50px 0 0;
  height: 80%;
  text-align: center;
  overflow: auto;
}

.btn-add {
  height: 30% !important;
  width: 70%;
  border-radius: 10px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem !important;
  line-height: 117px;
  text-align: center;
}

.btn-adding {
  height: 100% !important;
  width: 34%;
  border-radius: 10px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem !important;
  line-height: 117px;
  text-align: center;
}

.input-location-name {
  width: 70%;
  height: 70px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-location-name:focus {
  outline: solid;
  outline-color: #9aa0ae;
}

.input-location-degree {
  width: 70%;
  height: 70px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-location-degree:focus {
  outline: solid;
  outline-color: #9aa0ae;
}

.select-input {
  width: 70%;
  left: 15%;
  background-color: black;
  border: 2px solid #545861;
}

@media (max-width: 1024px) {
  .input-location-name {
    width: 70%;
    height: 30px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 1rem;
    color: white;
  }

  .input-location-degree {
    width: 70%;
    height: 30px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 1rem;
    color: white;
  }

  .btn-adding {
    height: 100% !important;
    width: 34%;
    border-radius: 10px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem !important;
    line-height: 117px;
    text-align: center;
  }

}
</style>

<style>
.content-area > .btn-container > .v-input > .v-input__control > .v-input__slot {
  margin: 0;
  text-align: center;
  font-size: 2.5vw;
}

.content-area > .btn-container > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > #input-10 {
  max-height: none !important;
}

.content-area > .btn-container > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > .v-select__selection--comma {
  overflow: inherit;
}

.content-area > .btn-container > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}



</style>
